import { Container, Heading, Link, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import Footer from "../components/footer";
import Layout from "../components/layout";
import Navbar from "../components/navbar";
import Seo from "../components/seo";
import { translations } from "../translations";
import type { Translation } from "../types/main";

const t: Translation = {
	language: "en",
	translations: translations.en,
	singleLanguage: true,
};

export default function RedirectForStores() {
	return (
		<Layout>
			<Seo title="Flowby - Cut your next line" />
			<Navbar t={t} />
			<Container maxW="5xl" py={{ base: 12, md: 24 }}>
				<Stack textAlign="center" spacing={4}>
					<Heading as="h2" fontSize="3xl" textAlign="center">
						This is an old link
					</Heading>
					<Text fontSize="xl" color={"gray.700"}>
						Hi! You have tried to reach an old link to Flowby.
					</Text>
					<Text fontSize="xl" color={"gray.700"}>
						Store administrators should log in to our new admin application
						here:{" "}
						<Link color="teal.600" href="https://admin.flowby.io">
							admin.flowby.io
						</Link>
					</Text>
					<Text fontSize="xl" color={"gray.700"}>
						If you are a customer to a store using Flowby, please notify the
						staff that they are using QR signs with links that lead to an old
						version of Flowby. These signs should be taken down and replaced
						with new signs as soon as possible.
					</Text>
					<Text fontSize="xl" color={"gray.700"}>
						If you have any questions, please contact us: hello@flowby.io
					</Text>
				</Stack>
			</Container>
			<Container maxW="8xl" py={12}>
				<Footer t={t} />
			</Container>
		</Layout>
	);
}
